/**
 * Libs
 */
import { Grid } from '@mui/material';
import { useEffect, ReactElement } from 'react';
import { DateTime } from 'luxon';

/**
 * Components
 */
import { Stats } from '@components/global/ui';

/**
 * Data
 */
import {
  useGetItinerariesCountLazyQuery,
  ScalarComparisonEnum,
  ItineraryFulfilmentStateEnum,
  ItineraryStateEnum,
} from '@generated/graphql';

const Dashboard = (): ReactElement => {
  const [getItinerariesCount, { data, loading, error }] =
    useGetItinerariesCountLazyQuery();

  useEffect(() => {
    getItinerariesCount({
      variables: {
        newBookingFilter: {
          state: [ItineraryStateEnum.Paid],
          fulfilmentState: [ItineraryFulfilmentStateEnum.NewBooking],
        },
        pendingOrderFilter: {
          state: [ItineraryStateEnum.Paid],
          fulfilmentState: [ItineraryFulfilmentStateEnum.PendingOrder],
        },
        notReadyBookingFilter: {
          state: [ItineraryStateEnum.Paid],
          fulfilmentState: [
            ItineraryFulfilmentStateEnum.NewBooking,
            ItineraryFulfilmentStateEnum.PendingOrder,
            ItineraryFulfilmentStateEnum.PendingConfirmation,
            ItineraryFulfilmentStateEnum.FinalCheck,
            ItineraryFulfilmentStateEnum.AuditIssue,
            ItineraryFulfilmentStateEnum.FulfilmentIssue,
            ItineraryFulfilmentStateEnum.CancelRequested,
            ItineraryFulfilmentStateEnum.CancelInProgress,
            ItineraryFulfilmentStateEnum.ChangeRequested,
            ItineraryFulfilmentStateEnum.ChangeInProgress,
          ],
        },
        urgentBookingFilter: {
          state: [ItineraryStateEnum.Paid],
          fulfilmentState: [
            ItineraryFulfilmentStateEnum.NewBooking,
            ItineraryFulfilmentStateEnum.PendingOrder,
            ItineraryFulfilmentStateEnum.PendingConfirmation,
            ItineraryFulfilmentStateEnum.FinalCheck,
            ItineraryFulfilmentStateEnum.AuditIssue,
            ItineraryFulfilmentStateEnum.FulfilmentIssue,
            ItineraryFulfilmentStateEnum.CancelRequested,
            ItineraryFulfilmentStateEnum.CancelInProgress,
            ItineraryFulfilmentStateEnum.ChangeRequested,
            ItineraryFulfilmentStateEnum.ChangeInProgress,
          ],
          arriveAt: [
            {
              value: DateTime.now().plus({ days: 28 }).toISODate(),
              comparison: ScalarComparisonEnum.Lte,
            },
          ],
        },
      },
    });
  }, [getItinerariesCount]);

  if (loading || !data) return <span>Loading...</span>;
  if (error) return <span>`Error! ${error.message}`</span>;

  const {
    newBookings: { totalCount: newBookingsCount },
    notReadyBookings: { totalCount: notReadyBookingsCount },
    pendingOrders: { totalCount: pendingOrdersCount },
    urgentBookings: { totalCount: urgentBookingsCount },
  } = data;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={12} md={6} lg={3} xl>
        <Stats title="New Bookings" amount={newBookingsCount.toString()} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3} xl>
        <Stats title="Pending Orders" amount={pendingOrdersCount.toString()} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3} xl>
        <Stats
          title="Not Ready Bookings"
          amount={notReadyBookingsCount.toString()}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3} xl>
        <Stats
          title="Urgent Bookings"
          amount={urgentBookingsCount.toString()}
        />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
