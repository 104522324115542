/**
 * LIBS
 */
import { Breadcrumbs, Divider, Grid, Typography } from '@mui/material';

/**
 * COMPONENTS
 */
import Dashboard from '@components/Itinerary/Dashboard';

const HomePage = () => (
  <>
    <Typography variant="h3" gutterBottom display="inline">
      Dashboard
    </Typography>

    <Breadcrumbs aria-label="Breadcrumb" sx={{ marginTop: 2 }}>
      <Typography>Home</Typography>
    </Breadcrumbs>

    <Divider sx={{ marginY: 6 }} />

    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Dashboard />
      </Grid>
    </Grid>
  </>
);

export default HomePage;
